<template>

    <div class="index">
        <div class="header1" @click="$router.push('/')">
            <div class="header-l">
                <!-- <img src="../../assets/image/logo.png" alt> -->
                 <img src="../../assets/icon/ad.png" alt="" @click="$router.push('/')">
        <img src="../../assets/icon/shuxian1.png" alt="" style="margin:0 20px">
        <img src="../../assets/icon/xiehui.png" alt="">
            </div>
            <!-- <div class="header-c">
                <p class="p1">中国广告协会战略支持平台</p>
                <p>中国广告协会战略制作平台</p>
            </div> -->
        </div>
        <div class="main">
            <div class="content">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="账号登录" name="first">
                        <el-input
                                v-model="phone"
                                placeholder="请输入11位有效手机号码"

                                clearable
                                @focus="phoneErr=false"
                        >
                            <img slot="prefix" src="../../assets/image/shoujihao1.png" alt>
                        </el-input>
                        <div class="pipe flex align-center" v-show="phoneErr">
                            <img src="../../assets/image/tishi.png" alt>
                            <span>请输入11位有效手机号码！</span>
                        </div>
                        <el-input
                                placeholder="密码"
                                v-model="password"
                                :maxlength="16"
                                clearable
                                show-password
                        >
                            <img slot="prefix" src="../../assets/image/mima1.png" alt>
                        </el-input>
                        <el-row>
                            <el-col :span="12">
                                <el-checkbox v-model="checked">记住密码</el-checkbox>
                            </el-col>
                            <el-col :span="12">
                                <el-button type="text" class="right" @click="forget">忘记密码？</el-button>
                            </el-col>
                        </el-row>
                        <el-button :class="[(!password || !phone)?'active-btn': 'btn']" :disabled="!password || !phone"
                                   type="success" @click="login">登录
                        </el-button>
                        <!-- <el-row>
                          <el-col :span="12">
                            <div class="denglu flex align-center">
                              <img src="../../assets/image/weixin.png" alt>
                              <el-button type="text">微信登录</el-button>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="denglu flex align-center">
                              <img src="../../assets/image/zhifubao.png" alt>
                              <el-button type="text">支付宝登录</el-button>
                            </div>
                          </el-col>
                        </el-row> -->
                        <el-button type="text" class="reg" @click="$router.push('/register')">立即注册</el-button>
                    </el-tab-pane>
                    <el-tab-pane label="验证码登录" name="second">
                        <el-input
                                v-model="phone"
                                placeholder="请输入11位有效手机号码"
                                @blur="phoneBlur"
                                :maxlength="11"
                                clearable
                                @focus="phoneErr=false"
                        >
                            <img slot="prefix" src="../../assets/image/shoujihao1.png" alt>
                        </el-input>
                        <div class="pipe flex align-center" v-show="phoneErr">
                            <img src="../../assets/image/tishi.png" alt>
                            <span>请输入11位有效手机号码！</span>
                        </div>
                        <div class="div3-y flex align-center">
                            <input
                                    type="text"
                                    v-model="code"
                                    @blur="codeBlur"
                                    @focus="codeErr=false"
                                    placeholder="输入验证码"
                                    class="teshu flex-1"
                                    clearable
                            >
                            <el-button type="success" plain  :disabled="seconds > 0 || !phone" @click="verification">{{seconds > 0 ? seconds + 's' : '获取验证码'}}</el-button>
                            <!--<div class="time" @click="verification">{{seconds > 0 ? seconds + 's' : '获取验证码'}}</div>-->
                        </div>
                        <div class="pipe flex algin-center" v-show="codeErr">
                            <img src="../../assets/image/tishi.png" alt>
                            <span>输入验证码</span>
                        </div>
                        <el-button :class="[(!code || code<=3 || !phone)?'active-btn': 'btn']" :disabled="!code || code<=3 || !phone"
                                   type="success" @click="login">登录
                        </el-button>
                        <!-- <el-row>
                          <el-col :span="12">
                            <div class="denglu flex align-center">
                              <img src="../../assets/image/weixin.png" alt>
                              <el-button type="text" class="weixin">微信登录</el-button>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="denglu flex align-center">
                              <img src="../../assets/image/zhifubao.png" alt>
                              <el-button type="text">支付宝登录</el-button>
                            </div>
                          </el-col>
                        </!-->
                        <el-button type="text" class="reg regtop" @click="register">立即注册</el-button>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    const throttle = (func, wait = 50) => {
        // 上一次执行该函数的时间
        let lastTime = 0
        return function (...args) {
            // 当前时间
            let now = +new Date() // +new Date()是一个东西; +相当于.valueOf();
            // 将当前时间和上一次执行函数时间对比
            // 如果差值大于设置的等待时间就执行函数
            if (now - lastTime > wait) {
                lastTime = now
                func.apply(this, args)
            }
        }
    }
    export default {
        data() {
            return {
                activeName: 'first',
                phoneErr: false,
                pwdErr: false,
                phone: '',
                password: '',
                checked: false,
                seconds: 0,
                fromPath: 'main',
                code: '',
                codeErr: false
            }
        },
        methods: {
            // isreg(){
            //     this.$http.get(`/buyer/isExist?phone=`+this.phone).then(res=>{
            //         const {data} = res.data
            //         if (data===false){
            //             this.$message.error('您尚未注册,请先注册');
            //         }else {
            //             this.login()
            //         }
            //     })
            // },
            ...mapMutations({saveUser: 'USER'}),
            phoneBlur() {
                if (!this.$check.phone(this.$check.trim(this.phone))) {
                    this.phoneErr = true
                    return
                }
            },
            pwdBlur() {
                if (!this.$check.password(this.$check.trim(this.password))) {
                    this.pwdErr = true
                    return
                }
            },
            codeBlur() {
                console.log('===')
                if (!this.code) {
                    this.codeErr = true
                    return
                }
            },
            login() {
                this.phoneBlur()
                console.log(this.activeName)
                if (this.activeName == 'first') {
                    this.pwdBlur()
                    if (this.phoneErr) return;
                    const param = {
                        // password: this.$utils.encrypt(this.password.toUpperCase()),
                        password: this.password,
                        rememberMe: this.checked,
                        roleName: 'BUYER',
                        username: this.phone
                    };
                    localStorage.removeItem('TOKEN')
                    this.$http.post(this.$API.LOGIN, param).then(res => {
                        const {data} = res.data
                        const {token, buyer} = data
                        localStorage.setItem('TOKEN', token)
                        this.saveUser(buyer)
                        if (this.checked) {
                            localStorage.setItem('remember', 1)
                        } else {
                            localStorage.setItem('remember', 0)
                        }
                        this.$router.push({
                            name: 'main'
                        })
                    })
                    // this.$router.push({name: this.fromPath === 'register' ? 'main' : this.fromPath})
                } else {
                    this.codeBlur()
                    if (this.phoneErr || this.codeErr) return
                    const param = {
                        phone: this.phone,
                        code: this.code
                    }
                    localStorage.removeItem('TOKEN')
                    localStorage.removeItem('lat')
                    localStorage.removeItem('lng')
                    this.$http.get(`/buyer/phone/register?code=${param.code}&phone=${param.phone}`).then(res => {
                        const {data} = res.data
                        const {token, userinfo} = data
                        localStorage.setItem('TOKEN', token)
                        this.saveUser(userinfo)
                        this.$router.push({name: this.fromPath === 'register' ? 'main' : this.fromPath})
                    })
                }
            },
            handleClick(tab, event) {
                console.log(tab, event)
            },
            register() {
                this.$router.push('/register')
            },
            forget() {
                this.$router.push('/forget')
            },

            verification: throttle(function () {
                if (!this.$check.phone(this.phone)) {
                    this.phoneErr = true
                    return
                }
                if (this.seconds > 0) return
                this.$http.get(this.$API.GETCODE, {params: {mobile: this.phone}}).then(res => {
                    if (res.data.code === 0) {
                        // this.$notify({
                        //     title: '成功',
                        //     message: '验证码发送成功!',
                        //     type: 'success'
                        // })
                        this.$message({
                            message: '验证码发送成功!',
                            type: 'success'
                        });
                    } else {
                        this.$notify({
                            title: '提示',
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    //点击验证
                    this.seconds = 60
                    let timer = setInterval(() => {
                        //60秒倒计时
                        this.seconds--
                        if (this.seconds === 0) {
                            clearInterval(timer)
                            timer = null
                        }
                    }, 1000)
                })
            }, 500)
        },
        watch: {
            activeName() {
                this.phoneErr = false
                this.pwdErr = false
            }
        },
        beforeRouteEnter(to, from, next) {
            const {name} = from
            next(vm => {
                vm.fromPath = name
            })
        },
        beforeRouteLeave(to, from, next) {
            next(vm => {
                vm.pwdErr = false
                vm.phoneErr = false
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/common.scss';

    @mixin icon($m) {
        $url: '../../assets/image/' + $m;
        @include bgImage($url);
        background-size: contain;
    }

    .index {
        width: 100%;
        height: 100%;
        .log {
            padding: 52px 0;
            padding-left: 20%;
        }
        .header1 {
            width: 1200px;
            height: 149px;
            margin: 0 auto;
            > div {
                float: left;
            }
            .header-l {
                width: 404px;
                height: 149px;
                display: flex;
                align-items: center;
                img {
                    display: block;
                    width: 100%;
                    height: 58px;
                    // margin-top: 50px;
                }
            }
            .header-c {
                margin-left: 23px;
                p {
                    height: 27px;
                    font-size: 16px;
                    color: #333333;
                }
                .p1 {
                    margin-top: 46px;
                }
            }
            .header-r {
                width: 175px;
                font-size: 17px;
                line-height: 149px;
                float: right;
                text-align: right;
                span {
                    color: #666666;
                }
                a {
                    color: #d82229;
                }
            }

        }
        .main {
            width: 100%;
            background: url('../../assets/image/denglubeijing.png') no-repeat 0%/100% 100%;
            height: 600px;
            position: relative;
            .regtop {
                margin-top: 10px !important;
            }
            /deep/ .content {
                // min-height: 420px;
                min-height: 380px;
                width: 360px;
                background-color: #fff;
                left: 65%;
                top: 14.3%;
                position: absolute;
                border-radius: 10px;
                .pipe {
                    color: #d82229;
                    font-size: 12px;
                    margin-bottom: 10px;
                    transition: all 0.5s linear;
                    img {
                        vertical-align: sub;
                        padding: 0 5px 0 0;
                    }
                }

                .el-tabs__nav {
                    margin-left: 82px;
                    margin-top: 24px;
                    .el-tabs__item {
                        font-size: 18px;
                    }
                    .el-tabs__item.is-active {
                        color: #d82229;
                        font-weight: bolder;
                    }
                    .el-tabs__active-bar {
                        background-color: #d82229;
                    }
                    .el-tabs__item:hover {
                        color: #d82229;
                    }
                }
                .el-tabs__content {
                    margin: 0 32px 0;
                    .div3-y {
                        height: 45px;
                        margin-bottom: 30px;
                        .teshu {
                            width: 110px;
                            height: 43px;
                            border-radius: 5px;
                            color: #999999;
                            border: 1px solid #ccc;
                            background: url(../../assets/icon/yanzhengma.png) no-repeat 13px 13px;
                            float: left;
                            padding-left: 54px;
                        }
                        .el-button--success.is-plain{
                            margin-left: 5px;

                        }
                        .time {
                            width: 117px;
                            height: 45px;
                            background: #d82229;
                            color: #fff;
                            line-height: 45px;
                            text-align: center;
                            float: left;
                            margin-left: 12px;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                        .timedis {
                            background: #ccc;
                        }
                    }

                    .el-input {
                        margin: 0 0 10px;
                        .el-input__inner {
                            height: 45px;
                            width: 296px;
                            text-indent: 10px;
                        }
                        .el-input__prefix {
                            padding-top: 12px;
                            padding-left: 5px;
                        }
                        .el-input__inner:focus {
                            border-color: #d82229;
                        }
                    }
                    .el-input--suffix {
                        padding: 0 !important;
                    }
                    .el-checkbox:last-child {
                        margin-top: 10px;
                    }
                    .right {
                        margin-left: 80px;
                        color: #afafaf;
                    }
                    .btn {
                        height: 45px;
                        width: 296px;
                        margin-top: 25px;
                        background-color: #d82229;
                    }
                    .active-btn {
                        height: 45px;
                        width: 296px;
                        margin-top: 25px;
                        background-color: #d82229;
                        opacity: 0.6;
                    }
                    .denglu {
                        margin-top: 23px;
                        padding-left: 20px;
                        img {
                            margin-right: 10px;
                            vertical-align: bottom;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .el-button--text {
                        color: #afafaf;
                    }
                    .reg {
                        color: #d82229;
                        font-size: 16px;
                        display: block;
                        float: right;
                        margin-top: 10px;
                    }
                    .regtop {
                        margin-top: 38px;
                    }
                }
            }
        }
    }

    .checkbox-div {
  margin-top: 30px;
  margin-bottom: 30px;
  // 未选中样式
  /deep/ .el-checkbox__inner {
    border-radius: 0;
    background-color:#A8A8A8;
  }
  /deep/ .el-checkbox {
    color: #a8a8a8;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #6c7c92;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #6c7c92;
    border-color: #6c7c92;
  }
  // 加未选中的 对号 css
  .unselected .el-checkbox__input .el-checkbox__inner::after {
    // ::after {
    // 对号
    border: 2px solid red;
    // 不覆盖下面的 会 导致对号变形
    box-sizing: content-box;
    content: "";
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 3px;
    position: absolute;
    top: 1px;
    width: 3px;
    // }
  }
 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
     background-color: red;
    border-color: red;
 }
  /deep/ .el-checkbox__input .el-checkbox__inner::after {
    // 对号
    border: 2px solid #fff;
    // 不覆盖下面的 会 导致对号变形
    box-sizing: content-box;
    content: "";
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 3px;
    position: absolute;
    top: 1px;
    width: 3px;
    //
    transform: rotate(45deg) scaleY(1);
  }
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label{
    color: red !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
    background: #d82229;
    color: #d82229;
    border: #d82229;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        color: #d82229;
    }
}

/deep/ .el-checkbox__input .is-focus .el-checkbox__inner{
    border: #d82229;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,.el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #d82229;
    border-color: #d82229;
}
/deep/ .el-checkbox__inner:hover{
    border-color: #d82229;
}
/deep/ .el-checkbox__input .is-focus .el-checkbox__inner{
border-color: #d82229;
}
//移出复选框
/deep/ .el-button--success.is-disabled, .el-button--success.is-disabled:hover, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:active{
    color: #d82229;

}

.el-button--success{
    border:none;
}
</style>
